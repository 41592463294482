<template>
  <div>
    <el-button type="text" style="padding-top: 0" @click="add">{{ $t('main.button.add') }}</el-button>
    <field-mapper
        v-for="(item, index) in fields"
        :key="index"
        :target-registry-id="parseInt(targetRegistryId) || null"
        :task-registry-id="parseInt(taskRegistryId) || null"
        :value="{
          field: item.field,
          type: item.type,
          value: item.value
        }"
        @input="changeField($event, index)"
    >
    </field-mapper>
  </div>
</template>

<script>
import FieldMapper
  from '@/services/BPMNEditor/infrastructure/components/PropertiesPanel/PropertyEditors/UserTaskEditor/FieldMapping/FieldMapper.vue'

export default {
  name: 'FieldMapping',
  components: { FieldMapper },
  props: {
    taskRegistryId: {},
    targetRegistryId: {},
    extensions: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted () {
    const extensions = this.prepareExtensions(this.extensions)?.mapping
    this.fields = Object.keys(extensions?.fields || {}).map((field) => {
      return {
        field: field,
        type: extensions.fields[field].type,
        value: extensions.fields[field].value
      }
    })
  },
  data () {
    return {
      fields: []
    }
  },
  methods: {
    prepareExtensions (obj) {
      const result = {}

      // For each object path (property key) in the object
      for (const objectPath in obj) {
        // Split path into component parts
        const parts = objectPath.split('.')

        // Create sub-objects along path as needed
        let target = result
        while (parts.length > 1) {
          const part = parts.shift()
          target = target[part] = target[part] || {}
        }

        // Set value at end of path
        target[parts[0]] = obj[objectPath]
      }

      return result
    },
    add () {
      this.fields.push({
        field: null,
        type: null
      })
    },
    changeField (data, index) {
      this.$set(this.fields, index, data)
      console.log(this.fields)
      this.emitChanges()
    },
    emitChanges () {
      this.$emit('remove-extension', { filter: (_) => _.indexOf('mapping.fields.') === 0 })
      this.fields.forEach((item) => {
        if (item.field) {
          if (item.type) {
            this.$emit('change-extension', { name: `mapping.fields.${item.field}.type`, value: item.type })
          }
          if (item.value) {
            this.$emit('change-extension', { name: `mapping.fields.${item.field}.value`, value: item.value })
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
