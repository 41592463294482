<template>
  <el-popover
    placement="right"
    trigger="click">
    <el-form label-position="top" size="mini">
      <el-col :span="8">
        <registry-select-tree
            type="field"
            :value="{id: parseInt(model.field) || null}"
            :parent-id="parseInt(taskRegistryId) || null"
            @change="$set(model, 'field', $event.id)"
        />
      </el-col>
      <el-col :span="(!model.type || !showFieldValueInput) ? 16 : 8">
        <el-form-item>
          <el-select
            size="mini"
            v-model="model.type"
            @change="$set(model, 'value', null)"
            :placeholder="$t('task_editor.labels.field_type')">
            <el-option
              v-for="item in Object.keys(ruleFieldTypes)"
              :key="item"
              :label="ruleFieldTypes[item]"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col v-if="showFieldValueInput" :span="8">
        <el-form-item>
          <template v-if="isPropValue">
            <component
              size="mini"
              :is="mappedFilterType[model.type]"
              v-model="model.value"
              :placeholder="model.type && $t('task_editor.rule_field_types.' + model.type)"
              :field-type="'string_field'"
              :xref-list="[]">
            </component>
          </template>
          <template v-else>
            <component
              size="mini"
              :is="mappedFilterType[model.type]"
              :value="parseInt(model.value)"
              @input="$set(model, 'value', $event)"
              :placeholder="model.type && $t('task_editor.rule_field_types.' + model.type)"
              :field-type="'string_field'"
              :target-object-id="targetRegistryId">
            </component>
          </template>
        </el-form-item>
      </el-col>
    </el-form>
    <div class="mapping" slot="reference">
      <div class="mapping_label">
        {{ label }}
      </div>
    </div>
  </el-popover>
</template>

<script>
import Constant from '@/core/infrastructure/components/FilterType/Constant/index.vue'
import TargetTableField from '@/core/infrastructure/components/FilterType/TargetTableField.vue'
import Parameter from '@/core/infrastructure/components/FilterType/Parameter.vue'
import UsersTable from '@/core/infrastructure/components/FilterType/UsersTable.vue'
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'

export default {
  name: 'FieldMapper',
  components: {
    RegistrySelectTree,
    Constant,
    TargetTableField,
    Parameter,
    UsersTable
  },
  props: {
    taskRegistryId: {},
    targetRegistryId: {},
    value: Object
  },
  data () {
    return {
      model: this.value,
      ruleFieldTypes: this.$t('process_editor.element_properties.user_task.rule_field_types'),
      mappedFilterType: {
        constant: 'Constant',
        target_table_field: 'TargetTableField',
        parameter: 'Parameter',
        users_table: 'UsersTable'
      }
    }
  },
  watch: {
    model: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    }
  },
  computed: {
    showFieldValueInput () {
      return ['current_user', 'current_datetime'].indexOf(this.model.type) === -1
    },
    isPropValue () {
      return ['constant', 'parameter'].indexOf(this.model.type) !== -1
    },
    label () {
      if (!this.model?.field) {
        return this.$t('main.fields.select')
      }
      let label = []

      label.push('attr_' + this.model.field + '_')

      if (!this.model.type) {
        label.push(this.$t('process_editor.element_properties.not_selected'))
      }

      if (this.isPropValue) {
        label.push(this.model.value)
      }
      if (!this.showFieldValueInput) {
        label.push(this.ruleFieldTypes[this.model.type])
      }

      return label.join(' = ')
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../../../main";

.mapping {
  padding-bottom: 10px;
  cursor: pointer;
}

.mapping_label {
  color: #606266;
  border-bottom: 1px dashed #606266;
}
::v-deep .el-form-item {
  margin-bottom: 0;
}
</style>
